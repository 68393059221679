<script setup
        lang="ts">

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <g clip-path="url(#clip0_734_9946)">
    <path d="M5.25 7.5L9 11.25L12.75 7.5H5.25Z" fill="black"/>
  </g>
  <defs>
    <clipPath id="clip0_734_9946">
      <rect width="18" height="18" fill="currentColor"/>
    </clipPath>
  </defs>
</svg>
</template>

<style scoped>

</style>