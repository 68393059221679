<script setup
        lang="ts">

</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <mask id="mask0_327_7063" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
      <rect width="32" height="32" fill="currentColor"/>
    </mask>
    <g mask="url(#mask0_327_7063)">
      <path d="M16 29.3332L15.6667 25.3332H15.3333C12.1778 25.3332 9.5 24.2332 7.3 22.0332C5.1 19.8332 4 17.1554 4 13.9998C4 10.8443 5.1 8.1665 7.3 5.9665C9.5 3.7665 12.1778 2.6665 15.3333 2.6665C16.9111 2.6665 18.3833 2.96095 19.75 3.54984C21.1167 4.13873 22.3167 4.94984 23.35 5.98317C24.3833 7.0165 25.1944 8.2165 25.7833 9.58317C26.3722 10.9498 26.6667 12.4221 26.6667 13.9998C26.6667 15.6665 26.3944 17.2665 25.85 18.7998C25.3056 20.3332 24.5611 21.7554 23.6167 23.0665C22.6722 24.3776 21.55 25.5665 20.25 26.6332C18.95 27.6998 17.5333 28.5998 16 29.3332ZM15.3 21.2998C15.6778 21.2998 16 21.1665 16.2667 20.8998C16.5333 20.6332 16.6667 20.3109 16.6667 19.9332C16.6667 19.5554 16.5333 19.2332 16.2667 18.9665C16 18.6998 15.6778 18.5665 15.3 18.5665C14.9222 18.5665 14.6 18.6998 14.3333 18.9665C14.0667 19.2332 13.9333 19.5554 13.9333 19.9332C13.9333 20.3109 14.0667 20.6332 14.3333 20.8998C14.6 21.1665 14.9222 21.2998 15.3 21.2998ZM14.3333 17.0665H16.3333C16.3333 16.3998 16.4 15.9332 16.5333 15.6665C16.6667 15.3998 17.0889 14.9109 17.8 14.1998C18.2 13.7998 18.5333 13.3665 18.8 12.8998C19.0667 12.4332 19.2 11.9332 19.2 11.3998C19.2 10.2665 18.8167 9.4165 18.05 8.84984C17.2833 8.28317 16.3778 7.99984 15.3333 7.99984C14.3556 7.99984 13.5333 8.27206 12.8667 8.8165C12.2 9.36095 11.7333 10.0221 11.4667 10.7998L13.3333 11.5332C13.4444 11.1554 13.6556 10.7832 13.9667 10.4165C14.2778 10.0498 14.7333 9.8665 15.3333 9.8665C15.9333 9.8665 16.3833 10.0332 16.6833 10.3665C16.9833 10.6998 17.1333 11.0665 17.1333 11.4665C17.1333 11.8443 17.0222 12.1832 16.8 12.4832C16.5778 12.7832 16.3111 13.0887 16 13.3998C15.2222 14.0665 14.75 14.5943 14.5833 14.9832C14.4167 15.3721 14.3333 16.0665 14.3333 17.0665Z" fill="currentColor"/>
    </g>
  </svg>
</template>

<style scoped>

</style>