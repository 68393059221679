<template>
  <DialogBase class="w-full max-w-2xl text-dark-900" :open="upgradeDialog" @close="closeDialog">
    <h2 class="font-bold font-poppins text-h4s md:text-h4m mb-2">
      {{ t('need.more.video.minutes')}}
    </h2>
    <p v-if="user && user.info && user.info.plan" class="text-sm">
      {{ t('need.more.video.minutes.error.has.plan') }}
    </p>
    <p v-else class="text-sm">
      {{ t('need.more.video.minutes.error') }}
    </p>
    <div class="mt-2 grid gap-4 md:grid-cols-2">
      <div class="h-full">
        <h3 class="font-bold text-base">{{ t('top.up.video.minutes')}}</h3>
        <div v-if="credit_packages.length > 0">
          <p class="text-sm text-grey-500 mb-4">{{ t('purchase.credits.message') }}</p>
          <div class="">
            <div v-for="credit_package in credit_packages" :key="credit_package.id" class="rounded-s border border-grey-400 flex justify-between items-center gap-2 px-3 py-2 text-sm mb-2">
              <span><span class="font-bold">{{ getFirstWord($t(credit_package.t_name + '.short')) }}</span> {{getRemainingWords($t(credit_package.t_name + '.short'))}}</span>
              <button @click="purchasePackage(credit_package.id)" class="w-20 px-4 py-2 bg-brand-500 text-white font-bold rounded-s">
                ${{ credit_package.price }}
              </button>
            </div>
          </div>
          <p class="text-sm text-grey-500">{{ t('purchase.credits.note') }}</p>
        </div>
      </div>
      <div class="h-full">
        <h3 class="font-bold text-base">{{ t('upgrade.plan')}}</h3>
        <p class="text-sm text-grey-500 mb-6 md:mb-4">{{ t('upgrade.plan.message') }}</p>
        <div class="grow md:h-[calc(100%-80px)] flex justify-center items-center rounded-s md:border md:border-grey-400 ">
        <NuxtLink :to="localePath('/account') + '#plans'" target="_blank" class="px-6 py-2 rounded-s bg-brand-500 text-white font-bold">
          {{ t('view.plans') }}
        </NuxtLink>
        </div>
      </div>
    </div>
  </DialogBase>
</template>

<script setup>
  import {useUtilsStore} from "~/store/utils";
  import {useCookieStore} from "~/store/cookie";
  import {useCreditStore} from "~/store/credit";
  import {storeToRefs} from "pinia";

  const config = useRuntimeConfig();
  const { $toast } = useNuxtApp();
  const {t} = useI18n({
    useScope: 'local'
  });

  const utilsStore = useUtilsStore();

  const cookieStore = useCookieStore();
  const creditStore = useCreditStore();

  const {user} = storeToRefs(cookieStore);
  const {upgradeDialog, upgradeDialogType} = storeToRefs(utilsStore);
  const {credit_packages, fetchError, stripeUrl} = storeToRefs(creditStore);

  await creditStore.getCreditPackages(upgradeDialogType.value, config);

  const getFirstWord = (str) => {
    if (!str) return '';
    return str.split(' ')[0];
  }

  const getRemainingWords = (str) => {
    if (!str) return '';
    return str.split(' ').slice(1).join(' ');
  }

  const purchasePackage = async (id) => {
    await creditStore.createPurchaseSession(id, config);
    if (fetchError.value) {
      $toast.error(t('error.something.went.wrong'));
    } else {
      if (stripeUrl.value) {
        await navigateTo(stripeUrl.value, {external: true});
      }
    }
  }

  const closeDialog = () => {
    upgradeDialog.value = false;
  }
</script>

<i18n lang="json">
{
  "en": {
    "need.more.video.minutes": "Need more video minutes?",
    "need.more.video.minutes.error": "You need more video minutes to continue. Purchase more minutes or upgrade your plan.",
    "need.more.video.minutes.error.has.plan": "You need more video minutes to continue. Purchase more minutes below.",
    "top.up.video.minutes": "Top up video minutes",
    "purchase.credits.message": "Purchase credits to use as you go.",
    "purchase.credits.note": "Credits are non-refundable and do not expire. You’ll be directed to Stripe to complete your purchase.",
    "view.plans": "View plans",
    "upgrade.plan": "Upgrade plan",
    "upgrade.plan.message": "Get more video minutes and other features.",
  },
  "de": {
    "need.more.video.minutes": "Mehr Videominuten benötigt?",
    "need.more.video.minutes.error": "Sie benötigen mehr Videominuten, um fortzufahren. Kaufen Sie weitere Minuten oder upgraden Sie Ihren Plan.",
    "need.more.video.minutes.error.has.plan": "Sie benötigen mehr Videominuten, um fortzufahren. Kaufen Sie unten weitere Minuten.",
    "top.up.video.minutes": "Videominuten aufladen",
    "purchase.credits.message": "Kaufen Sie Credits, um sie nach Bedarf zu verwenden.",
    "purchase.credits.note": "Credits sind nicht erstattbar und verfallen nicht. Sie werden zu Stripe weitergeleitet, um den Kauf abzuschließen.",
    "view.plans": "Pläne ansehen",
    "upgrade.plan": "Plan upgraden",
    "upgrade.plan.message": "Erhalten Sie mehr Videominuten und weitere Funktionen."
  },
  "fr": {
    "need.more.video.minutes": "Besoin de plus de minutes vidéo ?",
    "need.more.video.minutes.error": "Vous avez besoin de plus de minutes vidéo pour continuer. Achetez plus de minutes ou passez à un plan supérieur.",
    "need.more.video.minutes.error.has.plan": "Vous avez besoin de plus de minutes vidéo pour continuer. Achetez plus de minutes ci-dessous.",
    "top.up.video.minutes": "Recharger des minutes vidéo",
    "purchase.credits.message": "Achetez des crédits à utiliser selon vos besoins.",
    "purchase.credits.note": "Les crédits sont non remboursables et n'expirent pas. Vous serez redirigé vers Stripe pour finaliser votre achat.",
    "view.plans": "Voir les plans",
    "upgrade.plan": "Mettre à niveau le plan",
    "upgrade.plan.message": "Obtenez plus de minutes vidéo et d'autres fonctionnalités."
  },
  "es": {
    "need.more.video.minutes": "¿Necesitas más minutos de video?",
    "need.more.video.minutes.error": "Necesitas más minutos de video para continuar. Compra más minutos o mejora tu plan.",
    "need.more.video.minutes.error.has.plan": "Necesitas más minutos de video para continuar. Compra más minutos a continuación.",
    "top.up.video.minutes": "Recargar minutos de video",
    "purchase.credits.message": "Compra créditos para usarlos según sea necesario.",
    "purchase.credits.note": "Los créditos no son reembolsables y no caducan. Serás redirigido a Stripe para completar tu compra.",
    "view.plans": "Ver planes",
    "upgrade.plan": "Mejorar plan",
    "upgrade.plan.message": "Obtén más minutos de video y otras funciones."
  },
  "ja": {
    "need.more.video.minutes": "ビデオ分数がもっと必要ですか？",
    "need.more.video.minutes.error": "続行するには、ビデオ分数がもっと必要です。追加の分数を購入するか、プランをアップグレードしてください。",
    "need.more.video.minutes.error.has.plan": "続行するには、ビデオ分数がもっと必要です。下記で追加の分数を購入してください。",
    "top.up.video.minutes": "ビデオ分数を追加",
    "purchase.credits.message": "必要に応じて使用するクレジットを購入してください。",
    "purchase.credits.note": "クレジットは返金不可で、有効期限はありません。購入を完了するには、Stripeにリダイレクトされます。",
    "view.plans": "プランを見る",
    "upgrade.plan": "プランをアップグレード",
    "upgrade.plan.message": "より多くのビデオ分数やその他の機能を入手しましょう。"
  }
}
</i18n>

<style scoped>
dialog {
  min-height: 440px;
  @media (max-width: 640px) {
    min-height: 600px;
  }
}
</style>